// eslint-disable-next-line import/named
import { removeHeader } from "libs/api/interceptors";

// This is to call the API without any invalid credentials. Since this endpoint is open.
const UNAUTHORIZED_INTERCEPTOR = removeHeader("Authorization");

export default function receipt(client) {
  const path = "/receipt";
  return {
    get: (data) => client.request({
      endpoint: `${path}?data=${data}`,
      method: "GET",
      interceptors: [
        UNAUTHORIZED_INTERCEPTOR,
      ],
    }),
    getOrder: (data) => client.request({
      endpoint: `/receipt-v2?data=${data}`,
      version: "v2",
    }),
    sendByEmailV2: (data, email) => client.request({
      endpoint: `/receipt-v2/actions?data=${data}`,
      version: "v2",
      body: {
        type: "SEND_EMAIL",
        config: {
          email_address: email,
        },
      },
      method: "POST",
      interceptors: [
        UNAUTHORIZED_INTERCEPTOR,
      ],
    }),
    getPdf: (data) => client.request({
      version: "v2",
      endpoint: `/receipt-v2/actions?data=${data}`,
      body: {
        type: "DOWNLOAD_PDF",
        config: {},
      },
      method: "POST",
      interceptors: [
        UNAUTHORIZED_INTERCEPTOR,
      ],
    }).then((res) => res.sync_result.pdf_data),
  };
}
